<script>
import { useRouter } from '@/hooks/useRouter'
import { useStore } from '@/hooks/useStore'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'TransferPage',
  setup() {
    const { router } = useRouter()
    const { loading } = useMessage()
    const { sunMenu, setSunMenu, menu } = useStore('menu')
    const userData = localStorage.getItem('userData')
    const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
    if (!isLoggedIn) {
      router.replace({ name: 'auth-login' })
    }
    const loadingRef = loading()
    if (menu.value.length > 0) {
      setSunMenu(menu.value[0]).then(() => {
        router.replace({ name: sunMenu.value[0].route })
        setTimeout(() => {
          loadingRef.close()
        }, 800)
      })
    }
  },
}
</script>

<template>
  <div />
</template>

<style scoped lang="scss">

</style>
